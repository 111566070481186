import { expenseColNm } from "./expenseConstant"
import { jobOrderColNm } from "./jobOrderConstant"

export const subcontractApiNm = {
  getSubcontractWithPrice: "/subcontract/getSubcontractWithPrice",
  getSubcontract: "/subcontract/getSubcontract",
  getSubContractWithPrice: "/subcontract/getSubContractWithPrice",
  insertSubcontract: "/subcontract/insertSubcontract",
  updateSubcontract: "/subcontract/updateSubcontract",
  deleteSubcontract: "/subcontract/deleteSubcontract",
  sendEmail: "/subcontract/sendEmail"
}

export const subcontractJobApiNm = {
  getSubcontractJob: "/subcontract-job/getSubcontractJob",
  insertSubcontractJob: "/subcontract-job/insertSubcontractJob",
  updateSubcontractJob: "/subcontract-job/updateSubcontractJob",
  deleteSubcontractJob: "/subcontract-job/deleteSubcontractJob"
}

export const subcontractExpenseApiNm = {
  getSubcontractExpense: "/subcontract-expense/getSubcontractExpense",
  getSubcontractExpenseJobOrder: "/subcontract-expense/getSubcontractExpenseJobOrder",
  getSubcontractExpenseJob: "/subcontract-expense/getSubcontractExpenseJob",
  insertUpdateSubcontractExpenseBulk: "/subcontract-expense/insertUpdateSubcontractExpenseBulk",
  updateSubcontractExpense: "/subcontract-expense/updateSubcontractExpense",
  deleteSubcontractExpense: "/subcontract-expense/deleteSubcontractExpense"
}

export const subcontractInvoiceApiNm = {
  getSubcontractInvoice: "/subcontract-invoice/getSubcontractInvoice",
  getSubcontractInvoiceTable: "/subcontract-invoice/getSubcontractInvoiceTable",
  getSubcontractPaymentVoucherReport: "/subcontract-invoice/getSubcontractPaymentVoucherReport",
  generateXpssDocNum: "/subcontract-invoice/generateXpssDocNum",
  insertSubcontractInvoice: "/subcontract-invoice/insertSubcontractInvoice",
  insertSubcontractInvoiceWithExpense: "/subcontract-invoice/insertSubcontractInvoiceWithExpense",
  uploadFile: "/subcontract-invoice/uploadFile",
  updateFileName: "/subcontract-invoice/updateFileName",
  deleteFile: "/subcontract-invoice/deleteFile",
  updateSubcontractInvoiceRmk: "/subcontract-invoice/updateSubcontractInvoiceRmk",
  updateSubcontractInvoiceColumn: "/subcontract-invoice/updateSubcontractInvoiceColumn",
  deleteSubcontractInvoice: "/subcontract-invoice/deleteSubcontractInvoice"
}

export const subcontractColNm = {
  ScId: "รถร่วม",
  Name: "ชื่อรถร่วม",
  SName: "ชื่อเรียก",
  Addr: "ที่อยู่",
  Tel: "โทร",
  TaxId: "เลขประจำตัวผู้เสียภาษี",
  Abbr: "ตัวย่อ",
  CtDscp: "ข้อมูลติดต่อ",
  CtMailOpr: "อีเมลฝ่ายปฏิบัติการ",
  CtMailAcc: "อีเมลฝ่ายบัญชี",
  XpssSupCod: "รหัสผู้ขาย Express",
  Rmk: "หมายเหตุ",
  Email: "Login E-mail",
  Pass: "Password",
}

export const subcontractJobColNm =  {
  JobSName: "ชื่องาน",
  ScId: "รถร่วม",
  SubNm: "รถร่วม",
  ContNo: jobOrderColNm.ContNo,
  ContSize: jobOrderColNm.ContSize,
  ContTyp: jobOrderColNm.ContTyp,
  TlrTyp: jobOrderColNm.TlrTyp,
  TlrTypId: jobOrderColNm.TlrTypId,
  UntPr: expenseColNm.UntPr,
  Prty: expenseColNm.Prty,
  Rmk: "หมายเหตุ",
  ModDte: "วันที่แก้ไข",
  ModAcc: "ผู้แก้ไข",
}

export const subcontractJobOrderColNm = {
  JobOrdId: jobOrderColNm.JobOrdId,
  AptTm: jobOrderColNm.AptTm,
  TakePlc: jobOrderColNm.TakePlc,
  RtnPlc: jobOrderColNm.RtnPlc,
  Loc: jobOrderColNm.Loc,
  Rmk: "หมายเหตุใบงาน",
  ScSNm: "รถร่วม",
}

export const subcontractInvoiceColNm = {
  ScInvId: "เลขที่ใบแจ้งหนี้รถร่วม",
  ScInvNo: "เลขที่ใบแจ้งหนี้รถร่วม",
  ScInvDte: "วันที่ใบแจ้งหนี้",
  XpssDocNum: "เลขเอกสาร Express",
  Rmk: "หมายเหตุ",
  AptTm: jobOrderColNm.AptTm,
  JobOrdId: jobOrderColNm.JobOrdId,
  JobNo: jobOrderColNm.JobNo,
  Bkg: jobOrderColNm.Bkg,
  ContNo: jobOrderColNm.ContNo,
  Loc: jobOrderColNm.Loc,
  CusSNm: jobOrderColNm.CusSNm,
  ScSNm: "รถร่วม",
  NumJobOrd: "จำนวนใบงาน",
  OwnRcptAmnt: "ใบเสร็จบริษัท",
  CusRcptAmnt: "ใบเสร็จลูกค้า",
  Total: "รวม",
  PVNo: "ใบเสำคัญจ่าย",
  Files: "Files"
}

export const subcontractExpenseColNm = {
  ExpId: "รายได้",
  ExpNm: "รายได้",
  JobOrdId: "เลขที่ใบงาน",
  VatPrct: "Vat(%)",
  VatPr: "Vat",
  WhtPrct: "หัก ณ ที่จ่าย(%)",
  WhtPr: "หัก ณ ที่จ่าย",
  UntPr: "ราคา",
  Qty: "จำนวน",
  Total: "รวม",
  PayAmnt: "ยอดจ่าย",
  IsOwnRcpt: "ประเภทใบเสร็จ",
  Rmk: "หมายเหตุ",
}